import React, { useState, useRef } from 'react'
import { Row, Col, Image, Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap-button-loader'
import { isMobile } from "react-device-detect"

const BASE_PATH = window.location.origin

const App = () => {
  const pageAboutUs = useRef(null)
  const pageStandard = useRef(null)
  const pageContactUs = useRef(null)
  const pageCustomer = useRef(null)

  const [applyJobDialog, setApplyJobDialog] = useState(false)

  const [detailDialog, setDetailDialog] = useState(false)
  const [qualificationDialog, setQualificationDialog] = useState(false)
  const [trainingDialog, setTrainingDialog] = useState(false)

  return (
    <div style={{ fontFamily: "Prompt" }}>

      { // Top Header 
        <>
          <div style={{ position: "fixed", width: "100vw", zIndex: 999, borderBottom: "1px solid #666" }}>

            {/* Top red banner */}
            <div style={{ height: 40, background: "#C0031B" }}></div>

            {/* Header */}
            <div style={{ height: 100, textAlign: "center", paddingTop: 20, background: "#EEE" }}>
              {/* Image */}
              <div style={{ display: "inline-block", verticalAlign: "top", marginTop: -5 }}>
                <Image src={`${BASE_PATH}/images/logo.png`} style={{ height: 78 }} />
              </div>

              {/* Email */}
              <div className="d-none d-sm-inline-block" style={{
                display: "inline-block", verticalAlign: "top", textAlign: "left",
                borderLeft: "2px solid #C0031B", paddingLeft: 10, height: 65, marginLeft: 10
              }}>
                <table>
                  <tr>
                    <td style={{ color: "#C0031B", fontWeight: "bold" }}>อีเมล์</td>
                    <td style={{ paddingLeft: 8, fontSize: 14 }}><a href="mailto:singtosecure@yahoo.com" style={{ color: "black" }}>singtosecure@yahoo.com</a></td>
                  </tr>

                  <tr>
                    <td style={{ color: "#C0031B", fontWeight: "bold" }}>เบอร์โทร</td>
                    <td style={{ paddingLeft: 8, fontSize: 14 }}><a href="tel:020047893" style={{ color: "black" }}>02 004 7893</a></td>
                  </tr>
                </table>
              </div>

              {/* Work hours */}
              <div style={{
                display: "inline-block", verticalAlign: "top", textAlign: "left",
                borderLeft: "2px solid #C0031B", paddingLeft: 10, height: 65, marginLeft: 10
              }}>
                <div style={{ color: "#C0031B", fontWeight: "bold" }}>เวลาทำการ</div>
                <div style={{ fontSize: 14 }}>สำนักงาน 08:30-18:00 น. ทุกวัน</div>
                <div style={{ fontSize: 14 }}>ฝ่ายปฏิบัติการ 24 ชั่วโมง ทุกวัน</div>
              </div>

              {/* Contacts */}
              <div className="d-none d-md-inline-block" style={{
                display: "inline-block", verticalAlign: "top", textAlign: "left",
                borderLeft: "2px solid #C0031B", paddingLeft: 10, height: 65, marginLeft: 10
              }}>
                {/* <Button style={{ background: "#C0031B", color: "#FFF", border: "none", marginTop: 20, marginLeft: 5 }}>
                  ขอใบเสนอราคา
                </Button> */}
              </div>
            </div>

            {/* Menu */}
            <div className="d-none d-md-block" style={{ height: 50, background: "rgba(0, 0, 0, 0.7)", paddingTop: 15, paddingBottom: 15, textAlign: "center" }}>
              <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20, fontWeight: "bold", textShadow: "2px 1px #000" }}>
                <Button className="btn-link" style={{ fontWeight: "bold", color: "red", textShadow: "2px 1px #000" }}
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >หน้าแรก</Button>
              </div>

              <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20 }}>
                <Button className="btn-link" style={{ fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}
                  onClick={() => pageAboutUs.current.scrollIntoView({ behavior: 'smooth' })}
                >เกี่ยวกับเรา</Button>
              </div>

              <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20 }}>
                <Button className="btn-link" style={{ fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}
                  onClick={() => pageStandard.current.scrollIntoView({ behavior: 'smooth' })}
                >มาตรฐานของเรา</Button>
              </div>

              {/* <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20, fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}>ผลงาน</div> */}

              <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20 }}>
                <Button className="btn-link" style={{ fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}
                  onClick={() => pageCustomer.current.scrollIntoView({ behavior: 'smooth' })}
                >ลูกค้าของเรา</Button>
              </div>

              <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20, fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}>
                <Button className="btn-link" style={{ fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}
                  onClick={() => setApplyJobDialog(true)}
                >สมัครงาน</Button>
              </div>

              <Modal show={applyJobDialog} size="lg">
                <div style={{ width: "100%", overflow: "hidden", zIndex: 1, position: "relative" }}>
                  <Image width="100%" src={`${BASE_PATH}/images/สมัครงาน.png`} />

                  <div style={{ width: "100%", zIndex: 2, position: "absolute", top: "20%" }}>
                    <div style={{ fontSize: "min(max(2.2vw, 16px), 24px)", fontWeight: "bold", color: "black", width: "100%", textAlign: "center" }}>
                      ร่วมงานกับเรา
                    </div>
                  </div>
                </div>

                <Modal.Body style={{ padding: 0 }}>
                  <div style={{ textAlign: "center", color: "#FFF", background: "#C0031B" }}>
                    <div style={{ paddingTop: 20, fontSize: 18, fontWeight: "bold" }}>รับสมัครงาน</div>
                    <div style={{ marginTop: 15, fontSize: 16, fontWeight: "bold" }}>ตำแหน่ง</div>
                    <div style={{ marginTop: 10, fontSize: 14, fontWeight: "bold" }}>พนักงานรักษาความปลอดภัย ชาย-หญิง (จำนวนมาก)</div>
                    <div style={{ marginTop: 5, fontSize: 14, fontWeight: "bold" }}>หัวหน้าพนักงานรักษาความปลอดภัย ชาย (จำนวนมาก)</div>
                    <div style={{ marginTop: 15, paddingBottom: 20, fontSize: 13 }}>สมัครด้วยตนเอง พร้อมสัมภาษณ์งาน (ทราบผลทัน)</div>
                  </div>

                  <div style={{ color: "#808080", fontSize: 14, marginBottom: 10, paddingLeft: 30 }}>
                    <Row>
                      <Col md={6}>
                        <Row>
                          <Col md={12} xs={12}
                          // style={{ borderRight: "1px solid #808080" }}
                          >
                            <div style={{ paddingTop: 20, fontSize: 16, paddingBottom: 15, color: "#000" }}>
                              คุณสมบัติ
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              ชาย-หญิง อายุ 20-55 ปี
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              มีสุขภาพแข็งแรงสมบูรณ์ ไม่เป็นโรคติดต่อร้ายแรง
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              รักงานบริการ ซื่อสัตย์ มีความรับผิดชอบสูง
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              สามารถอ่านและเขียนภาษาไทยได้ดี
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              เคยผ่านงานจะได้รับการพิจารณาเป็นพิเศษ
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {/* <div style={{ borderLeft: "1px solid #808080", display: "inline-block", height: "10%" }}></div> */}

                      <Col md={5}>
                        <Row>
                          <Col md={12} xs={8}>
                            <div style={{ paddingRight: 20, paddingTop: 20, fontSize: 16, paddingBottom: 15, color: "#000" }}>
                              เอกสารประกอบการสมัคร
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              1.สำเนาบัตรประชาชน
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              2.สำเนาทะเบียนบ้าน
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              3.สำเนาวุฒิการศึกษา
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              4.รูปถ่าย 1 นิ้ว 2 รูป
                            </div>
                            <div style={{ paddingTop: 5, fontSize: 12 }}>
                              5.ใบผ่านงาน(ถ้ามี)
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ background: "#242530", color: "#FFF", textAlign: "center" }}>
                    <div style={{ paddingTop: 10, marginTop: 10, fontSize: 13 }}>
                      ติดต่อสมัครงานได้ที่ ฝ่ายบุคคล โทร. <a href="tel:020047893" style={{ color: "black" }}>02 004 7893</a></div>
                    <div style={{ paddingBottom: 10, marginTop: 5, fontSize: 13 }}>
                      ทุกวันจันทร์-อาทิตย์ เวลา 08:30-18:00 น.</div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button style={{ background: "#C0031B", color: "#FFF", border: "none" }}
                    onClick={() => setApplyJobDialog(false)}
                  >Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <div style={{ display: "inline-block", marginLeft: 20, marginRight: 20, fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}>
                <Button className="btn-link" style={{ fontWeight: "bold", color: "#FFF", textShadow: "2px 1px #000" }}
                  onClick={() => pageContactUs.current.scrollIntoView({ behavior: 'smooth' })}
                >ติดต่อเรา</Button>
              </div>
            </div>
          </div>
          <div style={{ height: 140 }} />
        </>
      }

      { // Main Banner 
        <div style={{ background: "#EEE", position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%", overflow: "hidden", zIndex: 1 }}>
            <img src={`${BASE_PATH}/images/Group 318.jpg`} style={{ minWidth: "100%", minHeight: "100%" }} />
          </div>

          <div style={{ width: "100%", paddingBottom: 40, background: "rgba(0, 0, 0, 0.4)", position: "relative", zIndex: 2 }}>
            <div style={{
              paddingLeft: "14%", paddingTop: 90,
              fontSize: "min(max(3.2vw, 18px), 36px)", fontStyle: "italic", fontWeight: "bold", color: "red"
            }}>มุ่งสู่ความเป็นบริษัทชั้นนำด้านงานบริการ</div>
            <div style={{
              paddingLeft: "14%", paddingTop: 10,
              fontSize: "min(max(3.2vw, 18px), 36px)", fontStyle: "italic", fontWeight: "bold", color: "#FFF"
            }}>ที่พร้อมด้วยคุณธรรม จริยธรรม</div>
            <div style={{
              paddingLeft: "14%", paddingTop: 10,
              fontSize: "min(max(3.2vw, 18px), 36px)", fontStyle: "italic", fontWeight: "bold", color: "#FFF"
            }}>และรับผิดชอบต่อสังคม</div>
            <div style={{
              paddingLeft: "14%", paddingTop: 70,
              fontSize: "min(max(2.2vw, 16px), 30px)", fontStyle: "italic", fontWeight: "bold", color: "#FFF"
            }}>บริษัท รักษาความปลอดภัย สิงโต พัฒนา จำกัด</div>

            <div style={{ paddingLeft: "14%", visibility: isMobile ? "hidden" : "visible" }}>
              <Button style={{ background: "#C0031B", color: "#FFF", border: "none", marginTop: 20, marginLeft: 5, padding: "12px 25px" }}
                onClick={() => pageAboutUs.current.scrollIntoView({ behavior: 'smooth' })}>
                เกี่ยวกับเรา
            </Button>
            </div>
          </div>
        </div>
      }

      { // Home 
        <div style={{ background: "#C0031B", color: "#FFF", textAlign: "center", fontSize: 25, paddingTop: 30 }}>
          <div style={{ fontSize: "min(max(1.8vw, 18px), 28px)" }}>ให้บริการงานรักษาความปลอดภัย</div>
          <div style={{ fontSize: "min(max(1.8vw, 18px), 28px)" }}>ทั้งภาครัฐ และเอกชน ทั่วราชอาณาจักร</div>

          <div style={{ paddingTop: 20, paddingBottom: 35, textAlign: "center", fontSize: 12 }}>
            <div style={{ display: "inline-block" }} >
              <img src={`${BASE_PATH}/images/icon home1.png`}
                style={{ width: "30px", height: "30px", display: "inline-block" }} />
              <div style={{ marginTop: 5, marginLeft: 15, marginRight: 15, }}>หมู่บ้าน
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon condo2.png`}
                style={{ width: "35px", height: "35px", display: "inline-block" }} />
              <div style={{ marginTop: 5, marginLeft: 15, marginRight: 15, }}>คอนโด
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon hotel3.png`}
                style={{ width: "35px", height: "35px", display: "inline-block" }} />
              <div style={{ marginTop: 5, marginLeft: 15, marginRight: 15, }}>โรงแรม
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon building4.png`}
                style={{ width: "45px", height: "50px", display: "inline-block" }} />
              <div style={{ marginTop: -3, marginLeft: 15, marginRight: 15, }}>อาคารสำนักงาน
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon shoping bag5.png`}
                style={{ width: "30px", height: "35px", display: "inline-block" }} />
              <div style={{ marginTop: 2, marginLeft: 15, marginRight: 15, }}>ศูนย์การค้า
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon factory6.png`}
                style={{ width: "25px", height: "30px", display: "inline-block" }} />
              <div style={{ marginTop: 4, marginLeft: 15, marginRight: 15, }}>โรงงาน
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon warehouse7.png`}
                style={{ width: "35px", height: "35px", display: "inline-block" }} />
              <div style={{ marginTop: 4, marginLeft: 15, marginRight: 15, }}>คลังสินค้า
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon port8.png`}
                style={{ width: "40px", height: "35px", display: "inline-block" }} />
              <div style={{ marginTop: 2, marginLeft: 15, marginRight: 15, }}>ท่าเรือ
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon university9.png`}
                style={{ width: "40px", height: "33px", display: "inline-block" }} />
              <div style={{ marginTop: 1, marginLeft: 15, marginRight: 15, }}>มหาวิทยาลัย
            </div>
            </div>


            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon hospital10.png`}
                style={{ width: "35px", height: "40px", display: "inline-block" }} />
              <div style={{ marginTop: -2, marginLeft: 15, marginRight: 15, }}>โรงพยาบาล
            </div>
            </div>

            <div style={{ display: "inline-block", marginTop: 10 }} >
              <img src={`${BASE_PATH}/images/icon airport11.png`}
                style={{ width: "35px", height: "35px", display: "inline-block" }} />
              <div style={{ marginTop: 2, marginLeft: 15, marginRight: 15, }}>สนามบิน
            </div>
            </div>

          </div>
        </div>
      }

      { // Introduction 
        <div ref={pageAboutUs} style={{ background: "#EEE", position: "relative", scrollMargin: 190 }}>
          <div style={{ position: "absolute", minWidth: "100%", minHeight: "100%", overflow: "hidden", zIndex: 1 }}>
            <img src={`${BASE_PATH}/images/white-wallpapers.jpeg`} style={{ minWidth: "100%", minHeight: "100%" }} />
          </div>

          <div style={{ textAlign: "center", position: "relative", zIndex: 2 }}>
            <div style={{ paddingTop: 40 }}><Image src={`${BASE_PATH}/images/logo.png`} style={{ height: 100 }} /></div>
            <div className="header-text" style={{ fontSize: "min(max(3.2vw, 18px), 36px)" }}>บริษัท รักษาความปลอดภัย สิงโต พัฒนา จำกัด</div>
            <div style={{ marginTop: 20 }}><hr style={{ width: 80, background: "#C0031B", borderWidth: 2 }} /></div>
            <div style={{ display: "inline-block", maxWidth: 735, fontSize: 15, paddingLeft: 8, paddingRight: 8 }}>
              บริษัท รักษาความปลอดภัย สิงโต พัฒนา จำกัด ก่อตั้งขึ้นเมื่อปี พ.ศ. 2557 โดย ดร.ณฐกร วัชรสินธุ์ กรรมการผู้จัดการ
              และการให้คำแนะนำของท่าน พลเอก กวี ประเคนรี ประธานที่ปรึกษา โดยมีวัตถุประสงค์เพื่อให้บริการงานรักษาความปลอดภัย
              กับหน่วยงานภาคเอกชน ภาครัฐ มีแนวคิดในการบริหาร ให้การบริการด้วยความเป็นธรรม และพร้อมซึ่งความมีมาตรฐาน
              ตามข้อกำหนดของงานรักษาความปลอดภัยทุกประการ
          </div>
          </div>
        </div>
      }

      { // Works 
        <>
          <Row style={{ marginTop: 10, padding: 10 }}>
            <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} style={{ zIndex: 2 }}>
              <Image width="100%" height="100%" src={`${BASE_PATH}/images/Group 316.jpg`} />
            </Col>
          </Row>

          <div style={{
            marginTop: 50, position: "relative", zIndex: 2, marginBottom: 50,
            height: 100, background: "#242530", color: "#FFF", textAlign: "center", fontSize: 25, paddingTop: 25
          }}>
            <div style={{ textAlign: "center", paddingTop: 5 }}>ผลงานการปฏิบัตงานรักษาความปลอดภัย</div>
          </div>

          <Row style={{ padding: 16 }}>
            <Col md={6} style={{ marginBottom: 40 }}>
              <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 3 }}>
                  <div style={{ verticalAlign: "left", position: "relative", zIndex: 2 }}>
                    <Image width="100%" height="100%" src={`${BASE_PATH}/images/suvarnabhumF.png`} />
                  </div>
                  <div style={{ width: "100%", background: "#C0031B", color: "#FFF", position: "relative", zIndex: 2 }}>
                    <div style={{
                      fontSize: "min(max(1.2vw, 12px), 16px)",
                      textAlign: "center", paddingTop: 20, paddingBottom: 20
                    }}>สนามบิน สุวรรณภูมิ ส่วนขยาย ½</div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 1 }}>
                  <div style={{ verticalAlign: "left", position: "relative", zIndex: 2 }}>
                    <Image width="100%" height="100%" src={`${BASE_PATH}/images/สาขาโคราช.png`} />
                  </div>
                  <div style={{ width: "100%", background: "#C0031B", color: "#FFF", position: "relative", zIndex: 2 }}>
                    <div style={{
                      fontSize: "min(max(1.2vw, 12px), 16px)",
                      textAlign: "center", paddingTop: 20, paddingBottom: 20
                    }}>สาขาโคราช</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div style={{
            marginTop: 50, position: "relative", zIndex: 2, marginBottom: 50,
            height: 100, background: "#C0031B", color: "#FFF", textAlign: "center", fontSize: 25, paddingTop: 25
          }}>
            <div style={{ textAlign: "center", paddingTop: 5 }}>การฝึกอบรม</div>
          </div>

          <Row style={{ padding: 16 }}>
            <Col md={6} style={{ marginBottom: 40 }}>
              <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 3 }}>
                  <div style={{ verticalAlign: "left", position: "relative", zIndex: 2 }}>
                    <Image width="100%" src={`${BASE_PATH}/images/ภาพดำเนินการฝึกอบรม.png`} />
                  </div>
                  <div style={{ width: "100%", background: "#C0031B", color: "#FFF", position: "relative", zIndex: 2 }}>
                    <div style={{
                      fontSize: "min(max(1.2vw, 12px), 16px)",
                      textAlign: "center", paddingTop: 11.5
                    }}>ภาพถ่ายดำเนินการฝึกอบรม </div>
                    <div style={{
                      fontSize: "min(max(1.2vw, 12px), 16px)",
                      textAlign: "center", paddingBottom: 10.5
                    }}>
                      ระหว่างวันที่ 26-28 มีนาคม 2562 </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 1 }}>
                  <div style={{ verticalAlign: "left", position: "relative", zIndex: 2 }}>
                    <Image width="100%" src={`${BASE_PATH}/images/ซ้อมดับเพลิง.png`} />
                  </div>
                  <div style={{ width: "100%", background: "#C0031B", color: "#FFF", position: "relative", zIndex: 2 }}>
                    <div style={{
                      fontSize: "min(max(1.2vw, 12px), 16px)",
                      textAlign: "center", paddingTop: 20, paddingBottom: 20
                    }}>ฝึกซ้อมดับเพลิง (แวนเทจ คอนโดมิเนียม)</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      }

      { // Vision
        <div>
          <div style={{ position: "absolute", width: "100%", height: "100%", overflow: "hidden", zIndex: 1 }}>
            <img src={`${BASE_PATH}/images/bgvision.jpg`} style={{ minWidth: "100%", height: "400px" }} />
          </div>
          <div style={{
            marginTop: 50, color: "#FFF", textShadow: "1px 1px #000",
            textAlign: "center", fontSize: 30, paddingTop: 50, paddingBottom: 10, position: "relative", zIndex: 2
          }}>
            <div style={{ fontSize: "min(max(2.0vw, 20px), 32px)" }}>วิสัยทัศน์</div>
            <div style={{ paddingTop: 10, fontSize: 40 }}>“</div>
            <div style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto", marginTop: -40, marginBottom: 50 }}>
              <Row>
                <Col style={{ marginTop: 20 }} md={12}>
                  <Row>
                    <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} style={{ marginTop: 5 }}>
                      <div style={{ paddingTop: 10, paddingLeft: 8, paddingRight: 8, fontSize: "min(max(1.8vw, 18px), 28px)" }}>
                        มุ่งสู่ความเป็นบริษัทชั้นนำด้านงานบริการ ที่พร้อมด้วยคุณธรรม จริยธรรม และรับผิดชอบต่อสังคม
                    </div>
                      <div style={{ paddingTop: 10, fontSize: 40 }}>”</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      }

      { // Mission 
        <div style={{ background: "#EEE", position: "relative", marginBottom: 60 }}>
          <div style={{ position: "absolute", minWidth: "100%", minHeight: "100%", overflow: "hidden", zIndex: 1 }}>
            <img src={`${BASE_PATH}/images/white-wallpapers.jpeg`} style={{ minWidth: "100%", height: "100%" }} />
          </div>
          <div style={{ color: "#000", textAlign: "center", fontSize: 30, paddingTop: 50, position: "relative", zIndex: 2 }}>

            <div style={{ fontWeight: "bold", fontSize: "min(max(2.0vw, 20px), 32px)" }}>พันธกิจ</div>

            <div style={{ textAlign: "left", maxWidth: 650, marginLeft: "auto", marginRight: "auto", marginTop: 30, marginBottom: 60 }}>
              <Row>
                <Col style={{ marginTop: 20 }} md={6}>
                  <Row>
                    <Col xs={{ span: 2, offset: 1 }} md={{ span: 12, offset: 0 }} style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#C0031B", color: "#FFF",
                        width: 40, height: 40, fontSize: 16, textAlign: "center", paddingTop: 8, fontWeight: "bold"
                      }}>1</div>
                    </Col>
                    <Col md={12} xs={8}>
                      <div style={{ paddingTop: 10, fontSize: 12 }}>
                        สร้างความเป็นเอกลักษณ์ของพนักงานรักษาความปลอดภัยด้วยการปลูกฝังแนวคิด ให้เกิดความ “แกล้วกล้า ซื่อสัตย์ มีวินัย ใส่ใจบริการ”
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col style={{ marginTop: 20 }} md={6}>
                  <Row>
                    <Col xs={{ span: 2, offset: 1 }} md={{ span: 12, offset: 0 }} style={{ marginTop: 5 }}>
                      <div style={{ borderRadius: "50%", background: "#C0031B", color: "#FFF", width: 40, height: 40, fontSize: 16, textAlign: "center", paddingTop: 8, fontWeight: "bold" }}>2</div>
                    </Col>
                    <Col md={12} xs={8}>
                      <div style={{ paddingTop: 10, fontSize: 12 }}>
                        มุ่งพัฒนาสมรรถนะ ด้านความรู้ ทักษะ ในการปฏิบัติงานตามมาตรฐานอาชีพของพนักงานรักษาความปลอดภัย
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col style={{ marginTop: 20 }} md={6}>
                  <Row>
                    <Col xs={{ span: 2, offset: 1 }} md={{ span: 12, offset: 0 }} style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#C0031B", color: "#FFF",
                        width: 40, height: 40, fontSize: 16, textAlign: "center", paddingTop: 8, fontWeight: "bold"
                      }}>3</div>
                    </Col>
                    <Col md={12} xs={8}>
                      <div style={{ paddingTop: 10, fontSize: 12 }}>
                        มุ่งพัฒนาสมรรถนะ ด้านทัศนคติ ด้วยการฝึกอบรมให้เข้าใจถึงการคิดดี พูดดี ทำดี และมีกระบวนการติดตาม ประเมินผลพร้อมทั้งสร้างแรงจูงใจด้วยการเสริมแรง
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col style={{ marginTop: 20 }} md={6}>
                  <Row>
                    <Col xs={{ span: 2, offset: 1 }} md={{ span: 12, offset: 0 }} style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#C0031B", color: "#FFF",
                        width: 40, height: 40, fontSize: 16, textAlign: "center", paddingTop: 8, fontWeight: "bold"
                      }}>4</div>
                    </Col>
                    <Col md={12} xs={8}>
                      <div style={{ paddingTop: 10, fontSize: 12 }}>
                        มุ่งพัฒนาระบบงาน เพื่อให้ทันกับการเปลี่ยนแปลง ทั้งด้านระบบการบริหาร ไอทีและเทคโนโลยีด้านรักษาความปลอดภัย
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col style={{ marginTop: 20 }} md={6}>
                  <Row>
                    <Col xs={{ span: 2, offset: 1 }} md={{ span: 12, offset: 0 }} style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#C0031B", color: "#FFF",
                        width: 40, height: 40, fontSize: 16, textAlign: "center", paddingTop: 8, fontWeight: "bold"
                      }}>5</div>
                    </Col>
                    <Col md={12} xs={8}>
                      <div style={{ paddingTop: 10, fontSize: 12 }}>
                        ออกแบบการปฏิบัติงานเพื่อความปลอดภัยในชีวิต ทรัพย์สินและ กำหนดราคาค่าบริการที่เหมาะสม ตรงกับความต้องการ จำเป็นสะท้อนถึงภาวะเศรษฐกิจ ระดับการจ้างงาน ของแต่ละภูมิภาค
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col style={{ marginTop: 20 }} md={6}>
                  <Row>
                    <Col xs={{ span: 2, offset: 1 }} md={{ span: 12, offset: 0 }} style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#C0031B", color: "#FFF",
                        width: 40, height: 40, fontSize: 16, textAlign: "center", paddingTop: 8, fontWeight: "bold"
                      }}>6</div>
                    </Col>
                    <Col md={12} xs={8}>
                      <div style={{ paddingTop: 10, fontSize: 12 }}>
                        บริหารงานด้วยการยึดมั่นในหลักคุณธรรม และคำนึงถึงจรรยาบรรณวิชาชีพของงานรักษาความปลอดภัย
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      }

      { // Standard
        <>
          <div ref={pageStandard} style={{ marginBottom: 50, scrollMargin: 190 }}>
            <div style={{ background: "#EEE", position: "relative", marginBottom: 60 }}>
              <div style={{ position: "absolute", width: "100%", height: "114%", overflow: "hidden", zIndex: 1 }}>
                <img src={`${BASE_PATH}/images/Group 317.jpg`} style={{ minWidth: "100%", minHeight: "100%", filter: "blur(3px)" }} />
              </div>
              <div style={{
                color: "#FFF",
                textAlign: "center", fontSize: 30,
                paddingTop: 50, position: "relative", zIndex: 2
              }}>
                <div style={{ textShadow: "2px 1px #000", fontSize: "min(max(2.0vw, 20px), 32px)" }}>มาตราฐานของเรา</div>

                <Row>
                  <Col sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                    <Row style={{ marginTop: 80, paddingBottom: 80 }}>
                      <Col style={{ background: "#C0031B", color: "#FFF", display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                        <Button className="btn-link"
                          onClick={() => setDetailDialog(true)}
                        >
                          <div style={{ width: "100%", marginTop: 25 }}>
                            <img src={`${BASE_PATH}/images/home-security.png`} style={{ width: "95px", height: "100px", display: "inline-block" }} />
                          </div>
                          <div style={{ paddingTop: 15, fontSize: 12 }}>รายละเอียดการให้บริการ </div>
                          <div style={{ fontSize: 12, paddingBottom: 30 }}> ด้านรักษาความปลอดภัย</div>
                        </Button>
                      </Col>

                      <Col style={{ background: "#C0031B", color: "#FFF", display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                        <Button className="btn-link"
                          onClick={() => setQualificationDialog(true)}
                        >
                          <div style={{ width: "100%", marginTop: 20 }}>
                            <img src={`${BASE_PATH}/images/id card.png`}
                              style={{ width: "100px", height: "100px", display: "inline-block" }} />
                          </div>
                          <div style={{ paddingTop: 20, fontSize: 12 }}>คุณสมบัติพนักงาน</div>
                          <div style={{ fontSize: 12, paddingBottom: 30 }}>รักษาความปลอดภัย</div>
                        </Button>
                      </Col>

                      <Col style={{ background: "#FFF", color: "#000", display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                        <Button className="btn-link"
                          onClick={() => setTrainingDialog(true)}
                        >
                          <div style={{ width: "100%", marginTop: 15 }}>
                            <img src={`${BASE_PATH}/images/Certificate.png`}
                              style={{ width: "115px", height: "125px", display: "inline-block" }} />
                          </div>
                          <div style={{ fontSize: 12, paddingBottom: 47, color: "#333" }}>มาตรฐานการฝึกอบรม</div>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Modal show={detailDialog} size="lg">
            <Modal.Body>
              <Row>
                <Col lg={3} className="d-none d-lg-inline-block">
                  <div style={{ display: "inline-block", verticalAlign: "left" }} >
                    <Image width="100%" src={`${BASE_PATH}/images/มาตราฐาน.png`} />
                  </div>
                </Col>
                <Col lg={9}>
                  <Row>
                    <Col>
                      <h5 style={{ marginTop: 20, fontWeight: "bold", fontSize: "min(max(2.2vw, 16px), 18px)" }}>
                        มาตรฐานงานรักษาความปลอดภัยแสดงไว้เพื่อเป็นตัวอย่าง</h5>
                      <div style={{ color: "#C0031B", fontWeight: "bold", fontSize: 15, marginBottom: 20 }}>
                        รายละเอียดการให้บริการด้านรักษาความปลอดภัย</div>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>1</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        บริการรักษาความปลอดภัยในพื้นที่ต่างๆโดยรอบโครงการหรือบริเวณพื้นที่ปฏิบัติงานเพื่อให้เกิดความมั่นใจว่าปลอดภัย
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>2</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        จัดเตรียมนำเสนอแผนการดำเนินการในเชิงป้องกันด้านรักษาความปลอดภัยและการจราจร
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>3</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        ควบคุมการจราจรในพื้นที่โครงการหรือพื้นที่ปฏิบัติงาน
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>4</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        ให้บริการด้วยความสุภาพและให้ความช่วยเหลือแนะนำเส้นทางผู้ที่มาติดต่อสอบถาม
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>5</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        ดูแลรักษาความปลอดภัยและป้องกันความเสียหายที่จะเกิดขึ้นโดยรอบพื้นที่โครงการหรือพื้นที่ปฏิบัติงาน
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>6</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        รายงานเหตุการณ์โดยเร่งด่วนแก่ผู้ว่าจ้างเกี่ยวกับความเสียหายที่อาจจะเกิดขึ้น
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>7</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        กรณีมีผู้รับเหมาเข้าทำงานต้องแนะนำให้ติดต่อและบัตรประชาชนจากผู้รับผิดชอบพร้อมแนบรายชื่อ
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>8</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        ควบคุมตรวจสอบพนักงานผู้รับเหมาในการนำสิ่งของออกโดยมีเอกสารอนุมัติเท่านั้น
                  </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button style={{ background: "#C0031B", color: "#FFF", border: "none" }}
                onClick={() => setDetailDialog(false)}
              >
                Close
          </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={qualificationDialog} size="lg">
            <Modal.Body style={{ background: "#F6F4F5" }}>
              <Row>
                <Col lg={3} className="d-none d-lg-inline-block" style={{ verticalAlign: "top" }}>
                  <div style={{ display: "inline-block", verticalAlign: "left", marginTop: 25 }}>
                    <Image width="100%" src={`${BASE_PATH}/images/คุณสมบัติ.png`} />
                  </div>
                </Col>
                <Col lg={9} style={{ display: "inline-block" }}>
                  <Row>
                    <Col>
                      <h5 style={{
                        background: "#F6F4F5", marginTop: 20, marginBottom: 10,
                        fontWeight: "bold", fontSize: "min(max(2.2vw, 16px), 18px)"
                      }}>
                        คุณสมบัติพนักงานรักษาความปลอดภัย
                  </h5>
                      <div style={{ background: "#F6F4F5", color: "#C0031B", fontWeight: "bold", fontSize: 15, marginBottom: 20 }}>
                        (เป็นไปตาม พ.ร.บ.ธุรกิจรักษาความปลอดภัย พ.ศ. 2558 )
                  </div>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>1</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        เพศชาย สัญชาติไทย อายุระหว่าง 20 - 55 ปี ชายมีความสูงไม่ต่ำกว่า 160 เซนติเมตร หญิงต้องดูมีความปราดเปรียวแข็งแรง มีความคล่องตัว
                </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>2</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        ไม่เป็นผู้ทุพพลภาพ หรือไร้ความสามารถ หรือเสมือนไร้ความสามารถ
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>3</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        มีสุขภาพแข็งแรงสมบูรณ์ ไม่เป็นโรคติดต่อร้ายแรง
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>4</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        ไม่ใช้สารเสพติดและไม่เคยต้องโทษในคดีอาญามาก่อนเว้นแต่คดีเกี่ยวกับความผิดลหุโทษหรือความผิดอันเกิดจากความประมาท
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>5</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        มีความประพฤติดี แต่งกายสุภาพเรียบร้อย บุคลิกเหมาะสมที่จะปฏิบัติหน้าที่ได้ดี
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>6</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        สามารถอ่านและเขียนภาษาไทยได้ดี
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>7</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        พนักงานรักษาความปลอดภัย จะต้องปฏิบัติตามเงื่อนไขที่บริษัทกำหนด
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>8</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        พนักงานรักษาความปลอดภัย มีวุฒิการศึกษาตามการศึกษาภาคบังคับ
                  </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ marginTop: 5 }}>
                      <div style={{
                        borderRadius: "50%", background: "#808080", color: "#FFF",
                        width: 25, height: 25, fontSize: 15, textAlign: "center", paddingTop: 1, fontWeight: "bold",
                        verticalAlign: "top", marginRight: 8, display: "inline-block"
                      }}>9</div>
                      <div style={{
                        width: "calc(100% - 33px)", padding: 0, fontSize: 12, color: "#808080",
                        display: "inline-block"
                      }}>
                        พนักงานรักษาความปลอดภัย ต้องผ่านการอบรมเรื่องงานรักษาความปลอดภัยจากบริษัท รักษาความปลอดภัยมาเรียบร้อยแล้ว
                  </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button style={{ background: "#C0031B", color: "#FFF", border: "none" }}
                onClick={() => setQualificationDialog(false)}
              >
                Close
          </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={trainingDialog} size="lg">
            {/* <Modal.Header>
          <h4 style={{ marginBottom: 0 }}>มาตรฐานการฝึกอบรม</h4>
        </Modal.Header> */}
            <Modal.Body>
              <div style={{ padding: "5px 14px 0" }}>
                <div style={{ display: "inline-block", verticalAlign: "left" }}>
                  <Image width="100%" height="100%" src={`${BASE_PATH}/images/การฝึกอบรม.png`} />
                </div>
                <div style={{
                  background: "#C0031B", color: "#FFF", border: "none", fontWeight: "bold",
                  fontSize: "min(max(2.2vw, 14px), 17px)", textAlign: "center", textAlignLast: "center"
                }}>
                  การฝึกอบรบและพัฒนาพนักงานรักษาความปลอดภัย
            </div>
              </div>

              {!isMobile &&
                <div style={{
                  background: "#FFF", color: "#000", border: "none", fontSize: 13.5
                  , paddingTop: 10, marginBottom: 10
                }} className="d-md-none d-sm-block">
                  แนวทางในการพัฒนาพนักงานรักษาความปลอดภัย เป็นการพัฒนาสมรรถณะด้านความรู้ ทักษะ และ เจตคติบริษัทได้ก่อตั้งสถานฝึกอบรม
                  รับอนุญาตเพื่อดำเนินการฝึกอบรมพนักงานรักษาความปลอดภัย ภายใต้ชื่อ "สิงโต เพาเวอร์" เป็นสถาณฝึกอบรมหลักสูตรการรักษาความ
                  ปลอดภัย ที่ได้รับอนุญาตจากคณะกรรมการกำกับธุรกิจรักษาความปลอดภัย (สำนักงานตำรวจแห่งชาติ) ทะเบียนเลขที่ กก9610060
                  เพื่อดำเนินการฝึกอบรมและพัฒนาพนักงานรักษาความปลอดภัย
            </div>
              }

              <div style={{
                background: "#FFF", color: "#000", border: "none", fontSize: 13.5, paddingLeft: 15
                , paddingTop: 10, marginBottom: 10, textAlign: "justify"
              }} className="d-none d-md-block">
                แนวทางในการพัฒนาพนักงานรักษาความปลอดภัย เป็นการพัฒนาสมรรถณะด้านความรู้ ทักษะ และ เจตคติ บริษัทได้ก่อตั้งสถานฝึกอบรม
          <div style={{ textAlign: "justify" }}>รับอนุญาตเพื่อดำเนินการฝึกอบรมพนักงานรักษาความปลอดภัย ภายใต้ชื่อ "สิงโต เพาเวอร์" เป็นสถาณฝึกอบรมหลักสูตรการรักษาความ</div>
                <div style={{ textAlign: "justify" }}>ปลอดภัย ที่ได้รับอนุญาตจากคณะกรรมการกำกับธุรกิจรักษาความปลอดภัย (สำนักงานตำรวจแห่งชาติ) ทะเบียนเลขที่ กก9610060</div>
                <div style={{ textAlign: "justify" }}>เพื่อดำเนินการฝึกอบรมและพัฒนาพนักงานรักษาความปลอดภัย</div>
              </div>
              <div style={{
                fontSize: "min(max(2.2vw, 15px), 18px)", color: "#C0031B",
                fontWeight: "bold", paddingLeft: 15
              }}>
                หลักสูตรการฝึกอบรมพนักงานรักษาความปลอดภัย จำนวน 10 หลักสูตร ประกอบด้วย
          </div>
              <div style={{ color: "#808080", fontSize: 14, marginBottom: 10, paddingLeft: 30 }}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={12} xs={12}>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          1. หลักสูตรความรู้เบื้องต้นเกี่ยวกับธุรกิจรักษาความปลอดภัย
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          2. หลักสูตรกฏหมายที่เกี่ยวข้องกับการรักษาความปลอดภัย
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          3. หลักสูตรการรักษาความปลอดภัยขั้นพื้นฐาน
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          4. หลักสูตรการเขียนรายงาน
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          5. หลักสูตรการเตรียมความพร้อมกรณีเหตุฉุกเฉิน
                  </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Row>
                      <Col md={12} xs={8}>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          6. หลักสูตรการติดต่อสื่อสาร
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          7. หลักสูตรหลักการใช้กำลัง
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          8. หลักสูตรการปฐมพยาบาลเบื้องต้น
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          9. หลักสูตรการจัดการจราจร
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          10. หลักสูตรการฝึกภาคสนาม
                  </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </div>


              <div style={{
                fontSize: "min(max(2.2vw, 15px), 18px)",
                color: "#C0031B", paddingLeft: 15, fontWeight: "bold"
              }}>
                โปรแกรมฝึกอบรมเพื่อพัฒนาพนักงานรักษาความปลอดภัยจำนว��� 8 กิจกรรม
          </div>
              <div style={{
                background: "#FFF", color: "#000", border: "none", fontSize: 13.5
                , paddingLeft: 15, paddingTop: 10, marginBottom: 10, textAlign: "justify"
              }}>
                จากการที่ได้ศึกษาในระดับปริญญาเอก ได้ทำการวิจัยเรื่อง "การพัฒนาโปรแกรมฝึกอบรมเพื่อเสริมสร้างสมรรถณะวิชาชีพของพนักงาน
            <div>รักษาความปลอดภัยตามหลักสูตรพุทธจิตวิทยา"ได้พัฒนาโปรแกรมฝึกอบรมโดยนำหลักการพัฒนาสมรรถณะของบุคคล หลักจิตวิทยา</div>
                <div>ผสานด้วยหลักพุทธธรรม เพื่อพัฒนาพนักงานรักษาความปลอดภัย ด้านความรู้ ทักษะ และเจตคติ เป็นการต่อยอด และเติมเต็มจากหลัก</div>
                <div>สูตรที่มีจำนวน 8 กิจกรรม</div>
              </div>
              <div style={{ color: "#808080", fontSize: 14, marginBottom: 10, paddingLeft: 30 }}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={12} xs={12}>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 1 รู้จักหน้าที่ รู้จักธรรมะ
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 2 แสงเทียนส่องใจ
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 3 ศีลธรรมนำความรู้ (หลักเบญจศีล)
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 4 สร้างความสุขด้วยรอยยิ้ม (หลักเบญจธรรม)
                  </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Row>
                      <Col md={12} xs={12}>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 5 ทำดีได้ดีมีที่นี่
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 6 สติมาปัญญาเกิด
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 7 รู้จักคิดชีวิตเปลี่ยน (หลักฆราวาสธรรม)
                  </div>
                        <div style={{ paddingTop: 10, fontSize: 12 }}>
                          กิจกรรมที่ 8 สายใยรัก
                  </div>

                      </Col>
                    </Row>
                  </Col>
                </Row>

              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button style={{ background: "#C0031B", color: "#FFF", border: "none" }}
                onClick={() => setTrainingDialog(false)}
              >
                Close
          </Button>
            </Modal.Footer>
          </Modal>
        </>
      }

      { // Customer Logo
        <>
          <div ref={pageCustomer} style={{ scrollMargin: 190, position: "relative", zIndex: 2, height: 175, background: "#C0031B", color: "#FFF", textAlign: "center", fontSize: 30, paddingTop: 60 }}>
            <div>ลูกค้าของเรา</div>
          </div>

          <Row style={{ paddingTop: 30 }}>
            <div style={{ height: "100%", zIndex: 1, marginTop: 20, marginBottom: 30 }}>
              <div style={{ backgroundSize: "543px 737px", display: "inline-block" }}>
                <Col md={3} lg={{ span: 2, offset: 1 }}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/t1.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/f2.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/Suvarnabhumi3.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/aleen4.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/rattana5.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={{ span: 2, offset: 1 }}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/klangplaza6.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/veranda7.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/CentralPhuket8.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/city.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/kcl10.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={{ span: 2, offset: 1 }}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/klangcasa11.png`} style={{ width: "200px", height: "125px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/NOVOVILLE12.png`} style={{ width: "200px", height: "110px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/supalaiVILLE13.png`} style={{ width: "200px", height: "110px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/MK14.png`} style={{ width: "200px", height: "115px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 55 }}>
                    <img src={`${BASE_PATH}/images/logolamchabang.png`} style={{ width: "265px", height: "50px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={{ span: 2, offset: 1 }}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/klangvilla16.png`} style={{ width: "200px", height: "110px", display: "inline-block" }} />
                  </div>
                </Col>

                <Col md={3} lg={2}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center" }}>
                  <div style={{ width: "100%", marginTop: 20 }}>
                    <img src={`${BASE_PATH}/images/soho17.png`} style={{ width: "200px", height: "110px", display: "inline-block" }} />
                  </div>
                </Col>

                {/* <Button style={{
                marginTop: 50, marginLeft: 305, textAlign: "center", padding: "10px 40px",
                fontSize: 16, background: "#FFF", color: "#C0031B", border: "1", borderColor: "#C0031B"
              }}>
                เพิ่มเติม
              </Button> */}
              </div>
            </div>
          </Row>
        </>
      }

      { // Customer List
        <>
          <div style={{
            display: "none", marginTop: 15, background: "#E2DEDE", textAlign: "center",
            paddingBottom: 30, paddingTop: 30
          }}>
            <div style={{
              display: "inline-block", fontWeight: "bold", verticalAlign: "top", fontSize: "min(max(2.2vw, 10px), 18px)"
              , color: "#C0031B", paddingRight: 30
            }}>จังหวัดที่ให้บริการ</div>
            <div style={{
              display: "inline-block", fontSize: "min(max(2.2vw, 10px), 13px)", fontWeight: "bold", paddingTop: 4
            }}>กรุงเทพ : สมุทรปราการ : นนทบุรี : ปทุมธานี : นครปฐม : สมุทรสาคร : ประจวบคีรีขันธ์ :  ชลบุรี :  นครราชสีมา :  ขอนแก่น : สระแก้ว
            </div>
          </div>

          <div style={{ textAlign: "center", color: "#FFF", background: "#C0031B" }}>
            <div style={{ paddingTop: 40, fontSize: 23, paddingBottom: 25, fontWeight: "bold" }}>
              ตัวอย่างรายชื่อลูกค้าแสดงไว้บางส่วน
            </div>
            <hr style={{ borderBottom: "2px solid #FFF", width: 300, margin: "auto" }} />
          </div>

          <Row style={{ color: "#FFF", background: "#C0031B", padding: "50px 10px 30px" }}>
            <Col md={6}>
              <Row>
                <Col xs={{ span: 10, offset: 2 }} sm={{ span: 11, offset: 1 }} lg={{ span: 8, offset: 4 }}>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    1. บริษัท มั่นคงเคหะการ จำกัด (มหาชน)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    2. บริษัท ศุภาลัย จำกัด (มหาชน)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    3. บริษัท เจ้าพระยามหานคร จำกัด (มหาชน)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    4. บริษัท เอเชี่ยน พร็อพเพอร์ตี้ จำกัด (มหาชน)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    5. โรงงานน้ำตาลและอ้อยตะวันออก จำกัด (มหาชน) (โรงงานสระแก้ว)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    6. บริษัท ฤทธา จำกัด
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    7. บริษัท เพาเวอร์ไลน์ จำกัด (มหาชน)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    8. ศูนย์การค้าคลังพลาซ่า (โคราช)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    9. โรงพยาบาล บำรุงเมือง
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    10. คลังสินค้า ไทคอนโลจิสติกส์ พาร์ค
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    11. บริษัท เฟรเชอร์ส พร็อพเพอร์ตี้ อินดัสเทรียล (ประเทศไทย) จำกัด
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    12. มหาวิทยาลัยรัตนบัณฑิต
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    13. ศูนย์การค้า เซ็นทรัลพลาซ่า (ภูเก็ต)
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row>
                <Col xs={{ span: 10, offset: 2 }} sm={{ span: 11, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    14. สถาณทูตออสเตรีย
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    15. โรงแรม อลีนตา รีสอร์ท ปราณบุรี
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    16. ท่าเรือเฟอร์รี่ (พัทยา - หัวหินเขาตะเกียบ)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    17. การไฟฟ้าคลองเตย
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    18. สนามบิน สุวรรณภูมิ ส่วนขยาย CC
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    19. โรงแรมแคนทารี (โคราช)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    20. บริษัท ซูมิโตโม อีเลคตริก ไวริ่ง ซิสเต็มส์ (ประเทศไทย) จำกัด (โรงงานโคราช)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    21. บริษัท แอคทูลั่ม จำกัด (โรงงานโคราช)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    22. บริษัท ชินเฮือง จำกัด (โรงงานโคราช)
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    23. บริษัท ไชน่า สเตท คอนสตรัคชั่น เอนจิเนียริ่ง (ประเทศไทย) จำกัด
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    24. หมู่บ้านลัดดารมย์
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    25. หมู่บ้านลาดพร้าว
                  </div>
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    26. แวนเทจ คอนโดมิเนียม
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      }

      { // Service Area
        <div style={{ background: "#EEE", position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%", overflow: "hidden", zIndex: 2 }}>
            <img src={`${BASE_PATH}/images/Group 317.jpg`} style={{ minWidth: "100%", filter: "blur(3px)" }} />
          </div>

          <Row style={{ padding: "50px 0" }}>
            <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 4, offset: 3 }} style={{ zIndex: 2, padding: 0 }}>
              <Image width="100%" src={`${BASE_PATH}/images/Service-area.png`} />
            </Col>
            <Col xs={{ span: 10, offset: 1 }} md={{ span: 3, offset: 0 }} lg={2} style={{ background: "#C0031B", color: "#FFF", zIndex: 2, paddingLeft: 0 }}>
              <div style={{ paddingLeft: 10 }}>
                <div style={{ paddingTop: 20, fontSize: "min(max(1.2vw, 16px), 25px)", whiteSpace: "break-spaces", wordBreak: "keep-all" }}>
                  <div style={{ display: "inline-block" }}>พื้นที่ให้บริการ </div>
                  <div style={{ display: "inline-block" }}>กรุงเทพ และต่างจังหวัด</div>
                </div>
                <div style={{ padding: "15px 0", fontSize: "min(max(1.2vw, 11px), 12px)", lineHeight: 2 }}>
                  กรุงเทพ สมุทรปราการ นนทบุรี ปทุมธานี นครปฐม สมุทรสาคร ประจวบคีรีขันธ์ ชลบุรี นครราชสีมา ขอนแก่น สระแก้ว
              </div>
              </div>
            </Col>
          </Row>
        </div>
      }

      { // Contact
        <div ref={pageContactUs} style={{ background: "#EEE", position: "relative", scrollMargin: 190 }}>
          {/* Background */}
          <div style={{ position: "absolute", width: "100%", height: "100%", overflow: "hidden", zIndex: 1 }}>
            <img src={`${BASE_PATH}/images/w3.jpg`} style={{ minWidth: "100%", minHeight: "100%" }} />
          </div>

          {/* Content */}
          <div style={{
            color: "#000", textAlign: "center",
            fontSize: 30, paddingTop: 50, paddingBottom: 50, position: "relative", zIndex: 2
          }}>
            <div style={{ paddingTop: 20, fontSize: "min(max(2.0vw, 20px), 32px)" }}>ติดต่อเรา</div>
            <div style={{ paddingTop: 10, fontSize: "min(max(1.8vw, 18px), 28px)" }}>บริษัท รักษาความปลอดภัย สิงโต พัฒนา จำกัด</div>

            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Row style={{ paddingTop: 50 }}>
                <Col md={3} lg={{ span: 2, offset: 2 }}
                  style={{ display: "inline-block", verticalAlign: "top", textAlign: "center", paddingLeft: 10, paddingBottom: 15 }}>
                  <div style={{ width: "100%", marginBottom: 20 }}>
                    <img src={`${BASE_PATH}/images/icon pin.png`} style={{ width: "55px", height: "55px", display: "inline-block" }} />
                  </div>
                  <div style={{ paddingBottom: 5, fontSize: 15, fontWeight: "bold", color: "#C0031B" }}>สำนักงานใหญ่</div>
                  <div style={{ padding: 0, fontSize: 12 }}>79/93 นวมินทร์ 86 ถนนนวมินทร์ แขวงรามอินทรา เขตคันนายาว กรุงเทพ 10230</div>
                </Col>

                <Col md={3} lg={2} style={{ paddingBottom: 15 }}>
                  <div style={{ width: "100%", marginBottom: 20 }}>
                    <img src={`${BASE_PATH}/images/icon tel.png`} style={{ width: "55px", height: "55px", display: "inline-block" }} />
                  </div>
                  <div style={{ paddingBottom: 5, fontSize: 15, fontWeight: "bold" }}>โทรศัพท์</div>
                  <div style={{ padding: 0, fontSize: 12 }}><a href="tel:020047893" style={{ color: "black" }}>02-004-7893</a></div>
                </Col>

                <Col md={3} lg={2} style={{ paddingBottom: 15 }}>
                  <div style={{ width: "100%", marginBottom: 20 }}>
                    <img src={`${BASE_PATH}/images/icon email.png`} style={{ width: "55px", height: "55px", display: "inline-block" }} />
                  </div>
                  <div style={{ paddingBottom: 5, fontSize: 15, fontWeight: "bold" }}>อีเมล</div>
                  <div style={{ padding: 0, fontSize: 12, whiteSpace: "nowrap" }}><a href="mailto:singtosecure@yahoo.com" style={{ color: "black" }}>singtosecure@yahoo.com</a></div>
                </Col>

                <Col md={3} lg={2} style={{ paddingBottom: 15 }}>
                  <div style={{ width: "100%", marginBottom: 20 }}>
                    <img src={`${BASE_PATH}/images/icon clock.png`} style={{ width: "55px", height: "55px", display: "inline-block" }} />
                  </div>
                  <div style={{ paddingBottom: 5, fontSize: 15, fontWeight: "bold" }}>เวลาเปิดทำการ</div>
                  <div style={{ padding: 0, fontSize: 12, whiteSpace: "nowrap" }}>สำนักงาน 8.30-18.00 ทุกวัน</div>
                  <div style={{ padding: 0, fontSize: 12, whiteSpace: "nowrap" }}>ฝ่ายปฏิบัติการ 24 ชั่วโมง ทุกวัน</div>
                </Col>
              </Row>
              <Row style={{ paddingTop: 20 }}>
                {!isMobile &&
                  <Col md={3} lg={{ span: 2, offset: 2 }}
                    style={{ display: "inline-block", verticalAlign: "top", textAlign: "center", paddingLeft: 10, paddingBottom: 15 }}
                    className="d-none d-md-block">
                    <div style={{ fontSize: 15, fontWeight: "bold", color: "#C0031B" }}>สาขาโคราช</div>
                    <div style={{ paddingTop: 5, fontSize: 12 }}>
                      398/9 หมู่ 1 ถนนราชสีมา-โชคชัย ต.หัวทะเล อ.เมือง จ.นครราชสีมา 30000
              </div>
                  </Col>
                }
                {!isMobile &&
                  <Col md={3} lg={2} style={{ paddingBottom: 15 }} className="d-none d-md-block">
                    <div style={{ fontSize: 15, fontWeight: "bold" }}>โทรศัพท์</div>
                    <div style={{ paddingTop: 5, fontSize: 12 }}><a href="tel:0825844002" style={{ color: "black" }}>082-584-4002</a></div>
                  </Col>
                }

                <Col md={3} lg={2} style={{ paddingBottom: 15 }}>
                  {/* Intentionally leave empty */}
                </Col>

                <Col md={3} lg={2} style={{ paddingBottom: 15 }}>
                  {/* Intentionally leave empty */}
                </Col>
              </Row>

            </div>
          </div>
        </div>
      }

      { // Google Map
        <div style={{ height: 355, overflow: "hidden", background: "#C0031B" }}>
          <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1OqVlV_-MHajYhgbJYFqmTGizmaMbUUMN"
            height="420" width="1920" style={{ marginTop: -55 }}>
          </iframe>
        </div>
      }

      { // Footer
        <div style={{ background: "#242530", textAlign: "center" }}>
          {/* <div style={{ display: "inline-block", verticalAlign: "top", marginTop: 40 }}>
            <Image src={`${BASE_PATH}/images/logo.png`} style={{ height: 100 }} />
          </div> */}

          <div style={{
            display: "inline-block", verticalAlign: "top", color: "#FFF",
            paddingLeft: 10, marginLeft: isMobile ? 10 : 20, paddingRight: 10,
          }}>
            <div style={{
              paddingTop: 30, fontSize: "min(max(3vw, 15.5px), 18px)",
              textAlign: "left", fontWeight: "bold"
            }}>บริษัท รักษาความปลอดภัย สิงโต พัฒนา จำกัด</div>

            <table style={{ marginTop: 15 }}>
              <tr>
                <td style={{
                  width: 111, fontWeight: "bold", textAlign: "left", verticalAlign: "top", fontSize: "min(max(2.2vw, 10px), 12px)"
                }}>สำนักงานใหญ่</td>
                <td style={{
                  textAlign: "left", fontSize: "min(max(2.2vw, 10px), 12px)"
                }}>79/93 นวมินทร์ 86 ถนนนวมินทร์ แขวงรามอินทรา เขตคันนายาว กรุงเทพ 10230 โทร. <a href="tel:020047893" style={{ color: "white" }}>02-004-7893</a></td>
              </tr>

              {/* [Mobile Only] Address */}
              {/* <tr className="d-xs-table-cell d-md-none">
                <td style={{ width: 111, fontWeight: "bold", textAlign: "left", verticalAlign: "top" }}>สำนักงานใหญ่</td>
                <td style={{ textAlign: "left" }}>79/93 นวมินทร์ 86 ถนนนวมินทร์ แขวงรามอินทรา เขตคันนายาว กรุงเทพ 10230</td>
              </tr>
              <tr style={{ paddingLeft: 30 }}>โทร <a href="tel:020047893" style={{ color: "white" }}>02-004-7893</a></tr> */}


              <tr>
                <td style={{
                  fontWeight: "bold", textAlign: "left", verticalAlign: "top", paddingTop: 12
                  , fontSize: "min(max(2.2vw, 10px), 12px)"
                }}>สาขาโคราช</td>
                <td style={{
                  textAlign: "left", paddingTop: 8
                  , fontSize: "min(max(2.2vw, 10px), 12px)"
                }}>398/9 หมู่ 1 ถนนราชสีมา-โชคชัย ต หัวทะเล อ.เมือง จ.นครราชสีมา 30000 โทร. <a href="tel:0825844002" style={{ color: "white" }}>082-584-4002</a></td>
              </tr>

              {/* [Mobile Only] Email */}
              <tr className="d-xs-table-cell d-md-none">
                <td style={{
                  fontWeight: "bold", textAlign: "left", verticalAlign: "top", paddingTop: 12
                  , fontSize: "min(max(2.2vw, 10px), 12px)"
                }}>อีเมล์</td>
                <td style={{
                  textAlign: "left", paddingTop: 8
                  , fontSize: "min(max(2.2vw, 10px), 12px)"
                }}><a href="mailto:singtosecure@yahoo.com" style={{ color: "white" }}>singtosecure@yahoo.com</a></td>
              </tr>

              {/* [Desktop Only] Email and Work Hours */}
              <tr>
                <td colSpan={2} style={{ textAlign: "left", paddingTop: 12, fontSize: "min(max(2.2vw, 10px), 12px)" }}>
                  <div className="d-none d-md-block">
                    <div style={{ display: "inline-block", textAlign: "left" }}>
                      <div style={{ paddingBottom: 5, fontWeight: "bold", }}>อีเมล์</div>
                      <div style={{ whiteSpace: "nowrap" }}><a href="mailto:singtosecure@yahoo.com" style={{ color: "white" }}>singtosecure@yahoo.com</a></div>
                    </div>

                    <div style={{
                      display: "inline-block", textAlign: "left"
                      , borderLeft: "2px solid #ccc", marginLeft: 20, paddingLeft: 20, fontSize: "min(max(2.2vw, 10px), 12px)"
                    }}>
                      <div style={{ paddingBottom: 5, fontWeight: "bold" }}>เวลาเปิดทำการ</div>
                      <div style={{ paddingBottom: 5, whiteSpace: "nowrap" }}>
                        สำนักงาน 8:30 - 18:00 ทุกวัน <span style={{ marginLeft: 8 }}>ฝ่ายปฏิบัติการ 24 ชั่วโมง ทุกวัน</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div style={{
            background: "#242530", marginRight: "auto", marginLeft: "auto", maxWidth: 800, color: "#FFF", fontSize: 9, paddingTop: 10,
            marginTop: 20, borderTop: "1px solid #FFF"
          }}>
            <Row>
              <Col xs={12} md={9}>
                <div style={{ textAlign: isMobile ? "center" : "left", marginBottom: 20, paddingLeft: 5 }}>
                  <div style={{ display: "inline-block", paddingRight: 4 }}>© 2014 Singto Patana Security Guard Co., LTD. All rights reserved.</div>
                  <div className="d-none d-sm-inline-block">Powered by <a href="http://teambscs.com">teambscs</a>.</div>
                </div>
              </Col>

              {!isMobile &&
                <Col md={3} className="d-none d-md-block">
                  <div style={{ textAlign: "right" }}>Designed by jirawan seepoon</div>
                </Col>
              }
            </Row>
          </div>
        </div>
      }
    </div>
  )
}
export default App
