import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './contents/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.min.css'

import './contents/App.css'
import './contents/sidebar.css'
import './contents/datepicker.css'
import './contents/button.css'
import './contents/form.css'
import './contents/text.css'
import './contents/card.css'
import './contents/cursor.css'
import './contents/modal.css'
import './contents/tooltip.css'
import './contents/mxgraph.css'
import './contents/quill.css'
import './contents/table.css'
import './contents/ie.css'
import './contents/svg.css'
import './contents/valuechain.css'
import './contents/layout.css'
import './contents/orgstruct.css'
import './contents/hover.css'
import './contents/video.css'
import './contents/sta.css'
import './contents/listcompany.css'

ReactDOM.render(
  <App />,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
